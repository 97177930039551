<template>
  <admin-base ref="base">
    <div slot="title">
      {{ !this.$route.params.id ? 'Create' : 'Update' }} store
    </div>
    <div class="card">
      <div class="card-body">
        <b-form @submit.prevent @submit="saveData">
          <b-row>
            <b-col md="6">
              <b-form-group label="Ativo">
                <b-form-checkbox v-model="data.enabled" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Aprovado">
                <b-form-checkbox v-model="data.approved" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Empresa">
                <b-form-select v-model="data.company_id" :options="companies" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Nome">
                <b-form-input v-model="data.name" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Email">
                <b-form-input v-model="data.email" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Descrição">
                <b-form-input v-model="data.description" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Telefone">
                <b-form-input v-model="data.phone" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Telemóvel">
                <b-form-input v-model="data.mobile" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Integration IDs">
                <b-form-input v-model="data.integrationIds" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-row cols="8">
                <b-col md="3">
                  <b-form-group label="Valor Entrega 1">
                    <money v-model="data.delivery1" v-bind="money" class="form-control" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Valor Entrega 2">
                    <money v-model="data.delivery2" v-bind="money" class="form-control" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Valor Entrega 3">
                    <money v-model="data.delivery3" v-bind="money" class="form-control" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Valor Entrega 4">
                    <money v-model="data.delivery4" v-bind="money" class="form-control" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Valor Entrega Privada 1">
                    <money v-model="data.delivery1Private" v-bind="money" class="form-control" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Valor Entrega Privada 2">
                    <money v-model="data.delivery2Private" v-bind="money" class="form-control" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Valor Entrega Privada 3">
                    <money v-model="data.delivery3Private" v-bind="money" class="form-control" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Valor Entrega Privada 4">
                    <money v-model="data.delivery4Private" v-bind="money" class="form-control" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col v-if="data.id" md="2">
              <b-form-group label="Delivery Area">
                <file-upload :url="`${apiHost}/admin/stores/${data.id}/deliveryArea`" accept=".kml" btn-label=".kml file" :headers="apiHeader.headers" @success="deliveryAreaUploaded" />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="IVA">
                <b-form-input v-model="data.taxrate" type="decimal" />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col md="9">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="goBack"
              >
                Cancel
              </b-button>
              <b-button
                v-if="this.$route.params.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="danger"
                class="ml-1"
                @click="onDelete"
              >
                Delete
              </b-button>
            </b-col>
            <b-col md="3" class="text-right">
              <b-button
                v-if="this.$route.params.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="showOrders"
              >
                Pedidos
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
  </admin-base>
</template>

<script>
import Vue from 'vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import * as bootstrapVue from 'bootstrap-vue'
import { Money } from 'v-money'
import FileUpload from 'v-file-upload'
import useJwt from '@/auth/jwt/useJwt'
import AdminBase from '@/views/admin/AdminBase.vue'

Vue.use(FileUpload)

export default {
  components: {
    AdminBase,
    BRow: bootstrapVue.BRow,
    BCol: bootstrapVue.BCol,
    BFormGroup: bootstrapVue.BFormGroup,
    BFormInput: bootstrapVue.BFormInput,
    BFormSelect: bootstrapVue.BFormSelect,
    BFormCheckbox: bootstrapVue.BFormCheckbox,
    BForm: bootstrapVue.BForm,
    BButton: bootstrapVue.BButton,
    Money,
    'file-upload': FileUpload,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      companies: {},
      money: {
        decimal: '.',
        thousands: ',',
        suffix: ' €',
        precision: 2,
        masked: false,
      },
      apiHost: process.env.VUE_APP_CORE_ENDPOINT,
      apiHeader: useJwt.getHeader(),
    }
  },
  mounted() {
    this.loadData()
  },
  created() {
  },
  methods: {
    loadData() {
      this.loadCompanies()
      if (!this.$route.params.id) {
        this.data = { enabled: true, approved: true }
      } else {
        this.$refs.base.showLoading()
        axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/${this.$route.params.id}/show`)
        .then(response => {
          this.data = response.data.store
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          this.$refs.base.hideLoading()
        })
      }
    },
    loadCompanies() {
      const self = this
      this.$refs.base.showLoading()
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/companies/list-select`, useJwt.getHeader())
      .then(response => {
        self.companies = response.data.companies.map(u => ({ value: u.id, text: u.name })) ?? []
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(error.response.data.error)
        this.errored = true
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    saveData() {
      this.errored = false
      this.$refs.base.showLoading()
      if (!this.$route.params.id) {
        axios.post(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/create`, this.data)
        .then(() => {
          this.$refs.base.showToast('Loja criada com sucesso')
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          if (!this.errored) this.goBack()
          this.$refs.base.hideLoading()
        })
      } else {
        axios.put(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/${this.$route.params.id}/update`, this.data)
        .then(() => {
          this.$refs.base.showToast('Loja atualizada com sucesso')
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          if (!this.errored) this.goBack()
          this.$refs.base.hideLoading()
        })
      }
    },
    onDelete() {
      this.errored = false
      if (window.confirm('Deseja realmente remover a loja?')) {
        axios.delete(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/${this.$route.params.id}`)
        .then(() => {
          this.$refs.base.showToast('Loja deletada com sucesso')
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          if (!this.errored) this.goBack()
          this.$refs.base.hideLoading()
        })
      }
    },
    deliveryAreaUploaded() {
      this.$refs.base.showToast('Áreas de entrega atualizada com sucesso')
    },
    goBack() {
      this.$refs.base.goBack()
    },
    showOrders() {
      this.$router.push({ name: 'admin-orders', params: { storeid: this.$route.params.id } })
    },
  },
}
</script>
